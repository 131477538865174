import React, { useRef, useEffect } from "react"

const { tableau } = window;

function TableauEmbed() {
    const ref = useRef(null);
    console.log(ref);
    // const CASalesReport = "https://us-west-2b.online.tableau.com/t/avenue8/views/Avenue8_NorCal_SalesReport_ONLINE_updated/Salesvs_List?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:margin=auto";
    const CASalesReport = "https://public.tableau.com/views/Avenue8_NorCal_SalesReport_ONLINE/Salesvs_List?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link"
    // const testReport = "https://us-west-2b.online.tableau.com/t/avenue8/views/Avenue8_NorCal_SalesReport_ONLINE_updated/Salesvs_List?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"

    const options = {
        device: "desktop",
        margin: "auto",
    }

    function initViz() {
        new tableau.Viz(ref.current, CASalesReport, options);
    }

    useEffect(() => {
        initViz();
    },[])

    return(
        <div>
            <div ref={ref}>
            </div>
        </div>
    );
}

export default TableauEmbed;